import React, { useState, useEffect } from "react"
import { Layout, Menu, Row, Col, Input, Button, Drawer, Space } from "antd"
import { useStaticQuery, StaticQuery, graphql } from "gatsby"

import { BrandsI } from "../../interface"

import rhrLogo from "../../assets/images/RHR-Logo.svg"
import chevronRight from "../../assets/images/ic_chevron_right_24px.svg"
import chevronLeft from "../../assets/images/ic_chevron_left_24px.svg"
import searchIcon from "../../assets/images/ic_search_24px.svg"
import identity from "../../assets/images/ic_perm_identity_24px.svg"
import expandMore from "../../assets/images/ic_expand_more_24px.svg"
import rhrLogoBlue from "../../assets/images/search-page-rhr-logo.png"
import imageFeature from "../../assets/images/Image-feature.png"
import chevronRed from "../../assets/images/ic_chevron_right_24px_red.svg"
import menuIcon from "../../assets/images/ic_nav_menu.svg"
import closeIcon from "../../assets/images/ic_close.svg"

import { HomeIcon } from "../../utilities/icons/icons"

import "./HeaderStyles.scss"

const { Search } = Input

interface Props {
  searchData?: any
  siteTitle?: string
  isBookingHeader?: boolean
}

const Header: React.FC<Props> = ({
  searchData,
  siteTitle = "",
  isBookingHeader,
}) => {
  const { Header } = Layout
  const { SubMenu } = Menu

  const [active, setActive] = useState<string>("not-active")
  const [activeSearch, setActiveSearch] = useState<string>("not-active")
  const [showResult, setShowResult] = useState<boolean>(false)
  const [search, setSearch] = useState<string>("")
  const [searchResult, setSearchResult] = useState<BrandsI[]>([])
  const [brand, setBrand] = useState<BrandsI[]>([])

  const [visible, setVisible] = useState(false);

  const [fullWidth,setFullwidth] = useState<number>();

  useEffect(() => {
    setFullwidth(global.window.innerWidth)
  })

  useEffect(() => {
    setSearchResult(searchData)
    setBrand(searchData)
    searchData.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
  })

  if (typeof window !== "undefined") {
    const sticky = document.getElementById("stickyBtn")
    window.onscroll = () => {
      if (window.pageYOffset >= 20) setActive("active")

      if (window.pageYOffset >= window.innerHeight)
        sticky?.classList.add("active")

      if (window.pageYOffset < window.innerHeight)
        sticky?.classList.remove("active")

      if (window.pageYOffset < 20) setActive("not-active")
    }
  }
  const openSearchField = () => {
    if (typeof window !== "undefined") {
      setActiveSearch("active")
      document.body.style.overflow = "hidden"
      if (activeSearch === "active") {
        document.body.style.overflow = "auto"
        return setActiveSearch("not-active")
      }
    }
  }

  const onSearch = val => {
    if (val !== "") {
      setShowResult(true)
      setSearch(val)
      return
    }
    setShowResult(false)
  }

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header
        className={`${active} ${isBookingHeader ? "is-booking-header" : ""}`}
      >
        <Row className="rhr-nav-container tarsier-container" justify="space-between">
          <Col span={6} className="logo">
            <a href="/"  >
              <img src={rhrLogo} alt="RHR Logo" />
            </a>
          </Col>

          <Col className="mobile-nav">

            <img src={searchIcon} alt="" onClick={openSearchField} style={{ paddingRight: "2rem" }} />
            <img src={menuIcon} alt="" onClick={showDrawer} />

          </Col>

          <Drawer
            onClose={onClose}
            visible={visible}
            width={fullWidth}
            closable={false}
          >
            <Row className="nav-drawer" justify="space-between">
         
            <Col span={6} className="logo">
            <a href="/">
              <img src={rhrLogo} alt="RHR Logo" />
            </a>
          </Col>
          
          <Col style={{display:"flex", cursor:"pointer"}} onClick={onClose} >
          <img src={closeIcon} alt="Close" />
          <h3 style={{color:"#fff", marginTop:"12px", marginRight:"1.2rem"}}>Close</h3> 
         </Col>

            </Row>

              <Menu theme="dark" mode="inline" style={{marginTop:"5rem"}}>
              <SubMenu key="1" title="About Us" >
                  <Menu.ItemGroup>
                    <Menu.Item key="setting:1" >
                      <a href="/about-us#who-we-are" >
                        Who We Are
                        <img src={chevronRight} alt="Chevron Right" />
                      </a>
                    </Menu.Item>
                    <Menu.Item key="setting:2">
                      <a href="/about-us#brand-promise">
                        Brand Promise
                        <img src={chevronRight} alt="Chevron Right" />
                      </a>
                    </Menu.Item>
                    <Menu.Item key="setting:3">
                      <a href="/about-us#brand-portfolio">
                        Brand Portfolio
                        <img src={chevronRight} alt="Chevron Right" />
                      </a>
                    </Menu.Item>
                    <Menu.Item key="setting:4">
                      <a href="/about-us#feel-safe-at-rhr">
                        Feel Safe at RHR
                        <img src={chevronRight} alt="Chevron Right" />
                      </a>
                    </Menu.Item>
                  </Menu.ItemGroup>
                </SubMenu>
                <SubMenu key="2" title="Our Brands" style={{marginTop:"2rem"}}>
                  {brand.filter(res => res.status === "PUBLISHED").length !==
                    0 && (
                      <Menu.ItemGroup>
                        {brand
                          .filter(
                            res => res.status === "PUBLISHED" && res.slug !== ""
                          )
                          .map((res, key) => (
                            <Menu.Item key={`brands:${key}`}>
                              <a href={`/brand/${res.slug}`}>
                                {res.name}
                                <img src={chevronRight} alt="Chevron Right" />
                              </a>
                            </Menu.Item>
                          ))}
                      </Menu.ItemGroup>
                    )}
                </SubMenu>
                <Menu.Item key="3" style={{marginTop:"2rem"}}>
                  <a href="https://www.gorewards.com.ph/partners/summit-hotels-and-resorts"  target="_blank">
                    Loyalty Program
                  </a>
                </Menu.Item>
                <Menu.Item key="4" style={{marginTop:"2rem"}}>
                  <a href="https://www.linkedin.com/in/robinsons-hotels-and-resorts-aa3749192/"  target="_blank">
                    Careers
                  </a>
                </Menu.Item>
                <Menu.Item key="6" style={{marginTop:"2rem"}}>
                  <img src={identity} alt="Identity Icon" />{" "}
                  <img src={expandMore} alt="Expand More Icon" />
                </Menu.Item>
              </Menu>

            <Row>


            </Row>
          </Drawer>


          <Col span={18} className="nav-menu">
            {!isBookingHeader ? (
              <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["0"]}>
                <SubMenu key="1" title="About Us">
                  <Menu.ItemGroup>
                    <Menu.Item key="setting:1">
                      <a href="/about-us#who-we-are">
                        Who We Are
                        <img src={chevronRight} alt="Chevron Right" />
                      </a>
                    </Menu.Item>
                    <Menu.Item key="setting:2">
                      <a href="/about-us#brand-promise">
                        Brand Promise
                        <img src={chevronRight} alt="Chevron Right" />
                      </a>
                    </Menu.Item>
                    <Menu.Item key="setting:3">
                      <a href="/about-us#brand-portfolio">
                        Brand Portfolio
                        <img src={chevronRight} alt="Chevron Right" />
                      </a>
                    </Menu.Item>
                    <Menu.Item key="setting:4">
                      <a href="/about-us#feel-safe-at-rhr">
                        Feel Safe at RHR
                        <img src={chevronRight} alt="Chevron Right" />
                      </a>
                    </Menu.Item>
                  </Menu.ItemGroup>
                </SubMenu>
                <SubMenu key="2" title="Our Brands">
                  {brand.filter(res => res.status === "PUBLISHED").length !==
                    0 && (
                      <Menu.ItemGroup>
                        {brand
                          .filter(
                            res => res.status === "PUBLISHED" && res.slug !== ""
                          )
                          .map((res, key) => (
                            <Menu.Item key={`brands:${key}`}>
                              <a href={`/brand/${res.slug}`}>
                                {res.name}
                                <img src={chevronRight} alt="Chevron Right" />
                              </a>
                            </Menu.Item>
                          ))}
                      </Menu.ItemGroup>
                    )}
                </SubMenu>
                <Menu.Item key="3">
                  <a href="https://www.gorewards.com.ph/partners/summit-hotels-and-resorts" target="_blank">
                    Loyalty Program
                  </a>
                </Menu.Item>
                <Menu.Item key="4">
                  <a href="https://www.linkedin.com/in/robinsons-hotels-and-resorts-aa3749192/" target="_blank" >
                    Careers
                  </a>
                </Menu.Item>
                <Menu.Item key="5" className="search" onClick={openSearchField}>
                  <div id="magnifying-glass"></div> Search
                </Menu.Item>
                <Menu.Item key="6">
                  <img src={identity} alt="Identity Icon" />{" "}
                  <img src={expandMore} alt="Expand More Icon" />
                </Menu.Item>
              </Menu>
            ) : (
              <div
                style={{
                  height: "100%",
                  color: "#fff",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <a className="return-txt-btn" href="/">
                  <HomeIcon /> <span>Return to homepage</span>
                </a>
              </div>
            )}
          </Col>

        </Row>
      </Header>
      <Layout className={`${activeSearch} search-container`}>
        <Row className="search-wrap">
          <Col span="24" className="text-center search-top">
            <p onClick={openSearchField} className="back-btn">
              <img src={chevronLeft} alt="Chevron Left" />
              Back
            </p>
            <img src={rhrLogo} alt="RHR Logo" />
          </Col>
          <Col span="24" className="form-search">
            <Search
              placeholder="What are you looking for?"
              allowClear
              enterButton={<img src={searchIcon} alt="Search Icon" />}
              size="large"
              onSearch={onSearch}
            />
            <span>Type above and press Enter to search.</span>
          </Col>
        </Row>
        <Row className="search-result">
          <Col
            className="no-search-result"
            style={showResult ? {} : { display: "none" }}
          >
            <p>
              <strong>Searched Results</strong>
            </p>
            <p>
              <span>
                {
                  searchResult.filter(res =>
                    res.name
                      .toLocaleLowerCase()
                      .includes(search.toLocaleLowerCase().trim())
                  ).length
                }
              </span>{" "}
              results were found for <span>“{search.trim()}”</span>
            </p>
          </Col>
          <Col
            span={24}
            className="search-default-view"
            style={showResult ? { display: "none" } : {}}
          >
            <article className="text-center">
              <img src={rhrLogoBlue} alt="RHR Logo Navy Blue" />
              <span>Type above and press Enter to search.</span>
            </article>
          </Col>
          <Col
            span={24}
            className="search-results-view"
            style={showResult ? {} : { display: "none" }}
          >
            <Row className="search-list">
              {searchResult
                .filter(res =>
                  res.name
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase().trim())
                )
                .map((res, key) => (
                  <Col key={key} xs={24} xl={8} md={15} className="search-card">
                    <div className="search-card-wrap">
                      <div className="search-card-img">
                        <img
                          src={imageFeature}
                          alt="Image Right"
                          className="img-responsive"
                        />
                      </div>
                      <article className="search-card-details">
                        <p>
                          <strong>{res.name}</strong>
                        </p>
                        {res.short_description}
                        <a
                          href={res.redirect_link}
                          target="_blank"
                          className="know-more"
                        >
                          Know More <img src={chevronRed} alt="Arrow Red" />
                        </a>
                      </article>
                    </div>
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </Layout>
    </>
  )
}

export default Header
