import React, { useEffect, useState } from "react"
import axios from "axios"
import { Layout, Row, Col, Input, Button, Menu } from "antd"

import { LoadingOutlined } from "@ant-design/icons"
import "./FooterStyles.scss"

import RhrLogo from "../../assets/images/RHR-Logo.svg"
import SendIcon from "../../assets/images/ic_send_24px.svg"
import MailIcon from "../../assets/images/ic_mail.svg"
import LocationIcon from "../../assets/images/ic_place_24px.svg"
import ContactIcon from "../../assets/images/ic_local_phone_24px.svg"
import Qrcode from "../../assets/images/app/qr-icon-sample.svg"
import AppStore from "../../assets/images/app/appstore.png"
import GooglePlay from "../../assets/images/app/googleplay.png"
import Visa from "../../assets/images/logos/visa.png"
import Ipay88 from "../../assets/images/logos/ipay88.png"
import Pcidss from "../../assets/images/logos/pcidss.png"
import Mastercard from "../../assets/images/logos/mastercard.svg"
import MobileIcon from "../../assets/images/ic_phone_iphone_24px.svg"
import KumuIcon from "../../assets/images/icons/kumu.png"

import CustomModal from "../CustomModal"
import { FooterI } from "../../interface"


import {
  FbIcon,
  TwitterIcon,
  IgIcon,
  LinkedinIcon,
  ViberIcon,
  TiktokIcon,
  YtIcon,
} from "../../utilities/icons/icons"

const { Footer } = Layout

const { SubMenu } = Menu;
interface Props {
  isBookingFooter?: boolean
  data?: any
}

const Index: React.FC<Props> = ({ isBookingFooter, data }) => {
  const [isEmailError, setIsEmailError] = useState<boolean>(false)
  const [email, setEmail] = useState<string>("")
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<string>("")
  const [modalTitle, setModalTitle] = useState<string>("")
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [footerData, setFooterData] = useState<FooterI[]>([])

  useEffect(() => {
    setFooterData(data.tarsiergraphql.getFooter);
  }, [footerData]);

  const handleEmailChange = e => {
    setEmail(e.target.value)
  }
  const handleSubscribe = email => {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.GATSBY_BASE_URL}email/subscribe`,
      data: { email },
    })
      .then(res => {
        console.log(res)
        setIsSuccess(true)
        setShowModal(true)
        setModalTitle(res.data.title)
        setModalMessage(res.data.message)
        setEmail("")
        setIsLoading(false)
      })
      .catch(err => {
        if (err.response.status === 400) {
          setIsEmailError(true)
          setErrorMessage("Email Address is already subscribed!")
          setIsLoading(false)
        }
      })
  }
  const onSubscribe = e => {
    e.preventDefault()
    if (email !== "") {
      const emailRegex = /\S+@\S+\.\S+/
      if (emailRegex.test(email)) {
        setIsLoading(true)
        setIsEmailError(false)
        handleSubscribe(email)
      } else {
        setIsEmailError(true)
        setErrorMessage("Email Adress is invalid")
      }
    } else {
      setIsEmailError(true)
      setErrorMessage("Email Adress is required")
    }
  }
  return (
    <>
      <footer>
        <Footer>
          <Layout className="tarsier-container">
            <div className="footer-container">
              <Row>
                <Col span={24}>
                  <Row className="ant-row-center">
                    <Col xs={24} xl={6} className="footer-logo-container">
                      <a href="#" className="footer-logo">
                        <img src={RhrLogo} alt="RHR Logo" />
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div>
                {!isBookingFooter ? (
                  <Row>
                    <div className="footer-left-section">
                      <Col className="footer-left-section-company">
                        <div className="web-view">

                          <h3>Company</h3>
                          <ul>
                            <li>
                              <a href="/about-us#who-we-are">About Us</a>
                            </li>
                            {/* <li>
                              <a href="https://www.gorewards.com.ph/partners/summit-hotels-and-resorts">Loyalty Program</a>
                            </li> */}
                            <li>
                              <a href="https://www.linkedin.com/in/robinsons-hotels-and-resorts-aa3749192/">
                                Careers
                              </a>
                            </li>
                          </ul>
                        </div>

                        <div className="mobile-view">
                          <Menu

                            mode="inline"
                            theme="dark"

                          >
                            <SubMenu key="sub1" title="Company" >
                              <Menu.Item key="9"><a href="/about-us#who-we-are">About Us</a></Menu.Item>
                              <Menu.Item key="10"> <a href="https://www.linkedin.com/in/robinsons-hotels-and-resorts-aa3749192/">
                                Careers
                              </a></Menu.Item>
                              <Menu.Item key="10">RHR Mobile App</Menu.Item>
                            </SubMenu>
                          </Menu>
                        </div>
                      </Col>
                      <Col className="footer-left-section-moreInfo">
                        <div className="web-view">
                          <h3>More Information</h3>
                          <ul>
                            <li>
                              <a href="/legal-notices">Legal Notices</a>
                            </li>
                            <li>
                              <a href="/data-privacy-and-cookies-policy">
                                Data Privacy and Cookies Policy
                              </a>
                            </li>
                          </ul>
                        </div>

                        <div className="mobile-view">
                          <Menu

                            mode="inline"
                            theme="dark"

                          >
                            <SubMenu key="sub2" title="More Informaiton">
                              <Menu.Item key="9"> <a href="/legal-notices">Legal Notices</a></Menu.Item>
                              <Menu.Item key="10">   <a href="/data-privacy-and-cookies-policy">
                                Data Privacy and Cookies Policy
                              </a></Menu.Item>
                
                            </SubMenu>
                          </Menu>
                        </div>
                      </Col>
                    </div>
                    <div className="footer-right-section">
                      <Col className="footer-right-section-contact">
                        <ul className="contact-list">
                          <li>
                            <div className="icon-key">
                              <img src={LocationIcon} alt="location" />
                            </div>
                            <div className="text-primary">
                              {footerData[0]?.location}
                            </div>
                          </li>
                          <li>
                            <div className="icon-key">
                              <img src={MobileIcon} alt="Contact" />
                            </div>
                            <div >
                              {footerData[0]?.mobile_nos.map((mobile_number) => (
                                <>
                                  <a className="text-primary" href={"tel:" + mobile_number.country_code + mobile_number.input}>
                                    { mobile_number.country_code } { mobile_number.input.slice(0, 3) } { mobile_number.input.slice(3, 6) }  { mobile_number.input.slice(6, 10) }
                                  </a> 
                                  <br/>
                                </>
                              ))}
                            </div>
                          </li>
                          <li>
                            <div className="icon-key">
                              <img src={ContactIcon} alt="Contact" />
                            </div>
                            <div>
                              { footerData[0]?.landline_nos.map((landline) => (
                                <>
                                  <a className="text-primary" href={"tel:" + landline.input}>
                                    {landline.input.slice(0, 3)} ({landline.input.slice(3, 4)}) {landline.input.slice(4, 8)} {landline.input.slice(8, 12)}
                                  </a>
                                  <br/>
                                </>
                              ))}
                            </div>
                          </li>
                          <li>
                            <div className="icon-key">
                              <img src={MailIcon} alt="Contact" />
                            </div>
                            <div>
                              { footerData[0]?.email_addresses.map((email) => (
                                <>
                                  <a
                                    className="text-primary"
                                    href={"mailto:" + email.input}
                                    style={{ padding: 0, paddingBottom: "4px", display: "block" }}
                                  > {email.input} </a>
                                </>
                              ))}
                            </div>
                          </li>
                        </ul>
                        <ul className="social-media-list">
                          { footerData[0]?.social_media.map((social) => (
                            <li>
                              <a
                                target="_blank"
                                href={social.url}
                              >
                                { social.social_media_type.name === "facebook" ? <FbIcon />
                                  : social.social_media_type.name === "instagram" ? <IgIcon />
                                  : social.social_media_type.name === "linkedin" ? <LinkedinIcon />
                                  : social.social_media_type.name === "twitter" ? <TwitterIcon />
                                  : social.social_media_type.name === "tiktok" ? <TiktokIcon />
                                  : social.social_media_type.name === "youtube" ? <YtIcon />
                                  : social.social_media_type.name === "viber_community" ? <ViberIcon />
                                  : social.social_media_type.name === "kumu" ? <img src={KumuIcon} alt="Kumu" />
                                  : null
                                }
                              </a>
                            </li>
                          ))}
                        </ul>
                      </Col>
                      <Col className="footer-right-section-email">
                        <Row gutter={[0, 24]}>
                          <Col span={24}>
                            <h3 className="stay-updated text-primary">Stay up to date</h3>
                            <p className="newsletter-instruction text-primary">
                              To receive our newsletter please add your email
                              address below.
                            </p>
                            <div className="input-wrapper">
                              <form onSubmit={onSubscribe}>
                                <Row gutter={10}>
                                  <Col span={19}>
                                    <Input
                                      size="large"
                                      value={email}
                                      placeholder="Enter your email address"
                                      onChange={handleEmailChange}
                                      className={isEmailError ? "error" : ""}
                                    />
                                  </Col>
                                  <Col span={4}>
                                    <Button
                                      size="large"
                                      type="primary"
                                      htmlType="submit"
                                      icon={
                                        isLoading ? (
                                          <LoadingOutlined />
                                        ) : (
                                          <img src={SendIcon} alt="Send" />
                                        )
                                      }
                                      style={{ lineHeight: 0 }}
                                    />
                                  </Col>
                                </Row>
                                {isEmailError && (
                                  <p className={"error-message"}>
                                    {errorMessage}
                                  </p>
                                )}
                              </form>
                            </div>
                          </Col>
                          {/* <Col span={24}>
                            <h3>Download the App</h3>
                            <div className="apps-container">
                              <div>
                                <img src={Qrcode} alt="QR Code" />
                              </div>
                              <div className="google-app-container">
                                <a
                                  href="https://www.apple.com/ph/app-store/"
                                  target="_blank"
                                >
                                  <img src={AppStore} alt="App Store" />
                                </a>
                                <a
                                  href="https://play.google.com/"
                                  target="_blank"
                                >
                                  <img src={GooglePlay} alt="Google Play" />
                                </a>
                              </div>
                            </div>
                          </Col> */}
                        </Row>
                      </Col>
                    </div>
                  </Row>
                ) : (
                  <Row
                    justify="space-between"
                    align="middle"
                    className="booking-layout-footer-wrapper"
                  >
                    <Col >
                      <div>
                        <ul className="booking-layout-logos">
                          <li>
                            <img src={Visa} alt="Visa" />
                          </li>
                          <li>
                            <img src={Ipay88} alt="Ipay88" />
                          </li>
                          <li>
                            <img
                              src={Mastercard}
                              alt="Mastercard"
                              className="mastercard"
                            />
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col>
                      <div className="logo-right-wrapper">
                        <img src={Pcidss} alt="PCIDSS" />
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </Layout>
        </Footer>
        <div className="footer-copyrights">
          <Layout>
            <Row>
              <Col span={12}>
                <p>© 2021 Robinsons Hotels and Resorts.</p>
              </Col>
              <Col span={12}>
                <p className="text-right">
                  Powered by White Cloak Technologies Inc.,
                </p>
              </Col>
            </Row>
          </Layout>
        </div>
      </footer>
      <CustomModal
        noCancelBtn
        closable={false}
        maskClosable={false}
        isSuccessIcon={isSuccess ? true : false}
        isWarningIcon={isSuccess ? false : true}
        titleMessage={modalTitle}
        visible={showModal}
        onOk={() => setShowModal(false)}
        message={modalMessage}
        okText={isSuccess ? "Great!" : "Ok"}
      />
    </>
  )
}

export default Index
