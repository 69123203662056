import React, { useState } from "react";
import { DatePicker, Input, Row, Col } from "antd";
import moment from "moment";

import { dateFormat } from "./initailValue";
import ExpandMore from "../../assets/images/ic_expand_more_24px.svg";
import ErrorMessage from "./ErrorMessage";

const { RangePicker } = DatePicker;

const AdvanceSearch = ({
  datePickerHotel,
  formID,
  dataPicker,
  blockDate,
  rooms,
  addAllPersons,
  showRooms,
  roomShow,
  addAndMinus,
  removeRoom,
  addRoom,
  gbookingLink,
  isSameDayBooking,
  setIsSameDayBooking
}) => {
  const [disabledDates, setDisabledDates] = useState();
  const [checkInDate, setCheckInDate] = useState(moment(datePickerHotel?.[0]))
  const [checkOutDate, setCheckOutDate] = useState(moment(datePickerHotel?.[1]))

  const handleFirstDatePick = (date) => {
    setDisabledDates(date)
    dataPicker(date)
    setCheckInDate(date?.[0])
    setCheckOutDate(date?.[1])
  };

  if (moment(checkInDate).isSame(checkOutDate) && datePickerHotel !== null) {
    setIsSameDayBooking(true)
  } else {
    setIsSameDayBooking(false)
  }

  return (
    <>
      {datePickerHotel !== null && formID === "formHotel3" && (
        <RangePicker
          placeholder={["Check-in Date", "Check-out Date"]}
          format={dateFormat}
          onCalendarChange={handleFirstDatePick}
          disabledDate={(date) => blockDate(date, disabledDates)}
          value={[checkInDate, checkOutDate]}
        />
      )}

      {formID !== "formHotel3" && (
        <RangePicker
          placeholder={["Check-in Date", "Check-out Date"]}
          format={dateFormat}
          onCalendarChange={handleFirstDatePick}
          disabledDate={(date) => blockDate(date, disabledDates)}
          defaultValue={null}
          value={datePickerHotel}
        />
      )}

      {isSameDayBooking && <ErrorMessage text='You have same day booking. Please select another checkout date' />}

      <div className="roominput">
        <Input
          placeholder={`${rooms.length} ${rooms.length > 1 ? `Rooms` : `Room`
            }: ${addAllPersons(rooms, "adult")} ${addAllPersons(rooms, "adult") > 1 ? `Adults` : `Adult`
            }, ${addAllPersons(rooms, "children")} ${addAllPersons(rooms, "children") > 1 ? `Children` : `Child`
            }`}
          className="room"
          onClick={showRooms}
        />
        {formID !== "formHotel3" && (
          <img
            src={ExpandMore}
            alt="Expand More"
            style={roomShow ? { transform: "rotate(180deg)" } : {}}
          />
        )}
        {formID === "formHotel3" && (
          <span
            className="expand-polygon"
            style={roomShow ? { transform: "rotate(135deg)", top: "20px" } : {}}
          ></span>
        )}
        <div
          id="roomList"
          style={
            roomShow ? { opacity: 1, zIndex: 1 } : { opacity: 0, zIndex: -1 }
          }
        >
          {rooms.map((room, key) => (
            <Row key={key} className="rooms-container">
              <Col span={5}>{room.label + ` ` + (key + 1)}</Col>
              <Col span={16}>
                <Row key={key}>
                  <Col span={12} className="text-center">
                    <span style={key !== 0 ? { display: "none" } : {}}>
                      Adult(s)
                    </span>
                    <div className="rooms-action">
                      <span
                        className="con-room minus-room"
                        style={
                          room.adult <= 1
                            ? { opacity: 0.5, pointerEvents: "none" }
                            : {}
                        }
                        onClick={() =>
                          addAndMinus(key, room.adult, "minus", "adult")
                        }
                      >
                        -
                      </span>
                      <span className="con-room children-adult--count">
                        {room.adult}
                      </span>
                      <span
                        className="con-room plus-room"
                        style={
                          room.adult >= 4
                            ? { opacity: 0.5, pointerEvents: "none" }
                            : {}
                        }
                        onClick={() =>
                          addAndMinus(key, room.adult, "add", "adult")
                        }
                      >
                        +
                      </span>
                    </div>
                  </Col>
                  <Col span={12} className="text-center">
                    <span style={key !== 0 ? { display: "none" } : {}}>
                      Children below 12
                    </span>
                    <div className="rooms-action">
                      <span
                        className="con-room minus-room"
                        style={
                          room.children <= 0
                            ? { opacity: 0.5, pointerEvents: "none" }
                            : {}
                        }
                        onClick={() =>
                          addAndMinus(key, room.children, "minus", "child")
                        }
                      >
                        -
                      </span>
                      <span className="con-room children-adult--count">
                        {room.children}
                      </span>
                      <span
                        className="con-room plus-room"
                        style={
                          room.children >= 2
                            ? { opacity: 0.5, pointerEvents: "none" }
                            : {}
                        }
                        onClick={() =>
                          addAndMinus(key, room.children, "add", "child")
                        }
                      >
                        +
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={3}>
                <div className="remove-room" onClick={() => removeRoom(key)}>
                  <span className="cross-room"></span>
                </div>
              </Col>
            </Row>
          ))}
          <Row className="room-footer">
            <Col span={8} className="addbutton-booking">
              <button
                className="rhr-button white"
                onClick={addRoom}
                disabled={rooms.length < 9 ? false : true}
              >
                ADD ROOM
              </button>
            </Col>
            <Col span={16} className="booking-details">
              <p className="text-right">
                Booking for more than 9 rooms?{" "}
                <a href={gbookingLink} target="_blank">
                  Click here
                </a>
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AdvanceSearch;
